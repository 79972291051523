import { Injectable } from '@angular/core';
import { from, mergeMap, Observable, reduce, retry, map } from 'rxjs';

import { DateAggregationOption } from '@/app/pages/explorer/planning-explorer/widgets/timeseries/timeseries.constants';
import { HttpService } from '../backend/common/api/http.service';
import { SimpleDateRange } from '../interfaces/common/date-range';
import { Segment } from '../interfaces/business/segment';
import { subtractYearsFromDateRange } from '@/utils/calendar';


export interface IGetDRPDataParams {
  interval?: DateAggregationOption;
  segment?: Segment;
  dateRange?: SimpleDateRange;
  groupingColumns: string[];
  planId: string;
  isActual?: boolean;
  forecastCollection: string;
  uom: string;
  useConstrained: boolean;
  drpTypes: string[];
}

export interface IDRPLoadedParams extends IGetDRPDataParams {
  displayedScenarios?: string[];
}

export interface IGetDRPImpactsChartDataParams extends IGetDRPDataParams {
  scenarios: Array<string | { id: string; events: string[] }>;
}

export interface IUpdateDRPParams extends IGetDRPDataParams {
  rowKey?: string;
  itemKey?: string;
  dateChange?: string;
  newValue?: number;
  valueType?: string;
}

export interface IValidateDRPParams {
  scenarioId?: string;
  planId?: string
}

export interface ISitAggregateResult {
  salesIn?: number;
  distributorSalesOut?: number;
  closingStocks?: number;
  stocksInTrade?: number;
  productId?: string;
  customerRef?: string;
  scenarioId?: string;
  hasNegativeValue?: boolean;
  hasSalesInSimulationData?: boolean;
  editable?: boolean;
}

export interface ISitTreeNode {
  key: string;
  label: string;
  data: Array<ISitAggregateResult | null>;
  children?: ISitTreeNode[];
}

export interface ISitDataResponse {
  columns: string[];
  rows: Array<[string, Array<ISitAggregateResult | null>]>;
  tree: ISitTreeNode[];
}

export interface ISitActualXResponse {
  columns: Array<[string, Array<string | null>]>;
  rows: Array<[string, Array<ISitAggregateResult | null>]>;
  tree: ISitTreeNode[];
}

export interface ISitValidationResponse {
  // scenarioId: string;
  msg: string;
  status: 200 | 400;
  result: {
    scenarioId: string;
  };
}

const enum DRPType {
  Actual = 'actual',
  Forecast = 'forecast',
  BaseDemand = 'baseDemand',
}

export enum DRP_VALUE_TYPE {
  CLOSING_STOCKS = 'closingStocks',
  SALES_IN = 'salesIn',
  DISTRIBUTOR_SALES_OUT = 'DistributorSalesOut',
  STOCKS_IN_TRADE = 'stocksInTrade'
}

@Injectable({ providedIn: 'root' })
export class DRPService {
  constructor(private readonly api: HttpService) { }

  getDRP(params: IGetDRPDataParams) {
    const types: DRPType[] = [];

    if (params.isActual) {
      types.push(DRPType.Actual, DRPType.BaseDemand);
    } else {
      types.push(DRPType.Forecast)
    }

    const init: ISitDataResponse = {
      columns: [],
      rows: [],
      tree: [],
    };

    return from(types).pipe(
      mergeMap(type => this.getByDRPType(params, type)),
      reduce((res, value) => {
        if (!value?.columns) {
          return res;
        }
        if (res.columns.length < 1) {
          res.columns = value.columns;
        }
        res.rows.push(...value.rows);
        res.tree.push(...value.tree);
        return res;
      }, init),
    )
  }

  getDRPForMultiActualX(params: IGetDRPDataParams, offsets: number[]): Observable<ISitActualXResponse> {
    const cases = offsets.map(offset => ({
      type: DRPType.Actual,
      offset,
      dateRange: subtractYearsFromDateRange(params.dateRange as SimpleDateRange, offset)
    }))

    const init: ISitActualXResponse = {
      columns: [],
      rows: [],
      tree: [],
    };

    return from(cases).pipe(
      mergeMap(c => {
        return this.getByDRPType({ ...params, dateRange: c.dateRange }, c.type).pipe(
          map(value => ({ value, c }))
        )
      }),
      reduce((res, { value, c }) => {
        if (!value?.columns) {
          return res;
        }

        if (res.columns.length < 1) {
          res.columns = [[`actual-${c.offset}`, value.columns] as [string, (string | null)[]]];
        } else {
          res.columns.push([`actual-${c.offset}`, value.columns] as [string, (string | null)[]]);
        }

        res.rows.push(
          ...value.rows
            .filter(r => r[0] === 'actual')
            .map(
              (r: [string, (ISitAggregateResult | null)[]]) => (
                [`actual-${c.offset}`, r[1]] as [string, (ISitAggregateResult | null)[]]
              )
            )
        );
        res.tree.push(
          ...value.tree
            .filter(t => t.key === 'actual')
            .map(
              t => ({
                ...t,
                key: `actual-${c.offset}`
              })
            )
        );

        return res;
      }, init),
    )
  }

  getDRPForActualX(params: IGetDRPDataParams, offset: number): Observable<ISitActualXResponse> {
    const types = [
      DRPType.Actual,
    ];
    const init: ISitActualXResponse = {
      columns: [],
      rows: [],
      tree: [],
    };
    const updatedParams = {
      ...params,
      dateRange: subtractYearsFromDateRange(params.dateRange as SimpleDateRange, offset)
    };
    return from(types).pipe(
      mergeMap(type => this.getByDRPType(updatedParams, type)),
      reduce((res, value) => {
        if (!value?.columns) {
          return res;
        }

        if (res.columns.length < 1) {
          res.columns = [[`actual-${offset}`, value.columns] as [string, (string | null)[]]];
        } else {
          res.columns.push([`actual-${offset}`, value.columns] as [string, (string | null)[]]);
        }

        res.rows.push(
          ...value.rows
            .filter(r => r[0] === 'actual')
            .map((r: [string, (ISitAggregateResult | null)[]]) => ([`actual-${offset}`, r[1]] as [string, (ISitAggregateResult | null)[]])
            )
        );
        res.tree.push(
          ...value.tree
            .filter(t => t.key === 'actual')
            .map(
              t => ({
                ...t,
                key: `actual-${offset}`
              })
            )
        );

        return res;
      }, init),
    )
  }

  updateDRP(params: IUpdateDRPParams) {
    return this.api.put('drp', params);
  }

  validateDRP(params: IValidateDRPParams) {
    return this.api.post('drp/validate', params);
  }

  triggerSalesInSimulation(params: IValidateDRPParams) {
    return this.api.post('drp/trigger-simulation', params);
  }

  private getByDRPType(params: IGetDRPDataParams, type: string): Observable<ISitDataResponse> {
    const delaySecond = 10 + (Math.random() * 10);
    const RETRY_COUNT = 10;

    return this.api.post('drp', {
      ...params,
      drpTypes: [type]
    }).pipe(
      retry({ count: RETRY_COUNT, delay: delaySecond * 1000 })
    );
  }
  
}
